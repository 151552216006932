<template>
  <div>
    <div class="">
      <!-- <h3>Информация о контракте</h3> -->
      <div class="card p-2">
        <div
          v-if="boughtProductsInfo && boughtProductsInfo.length"
          class="mb-2"
        >
          <table class="table table-hover table-bordered">
            <thead>
              <tr>
                <th>Nomi</th>
                <th>Soni</th>
                <th>Narxi</th>
                <th>Jami</th>
                <th>Kimdan</th>
                <th>Qachon kelgan</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(product,index) in boughtProductsInfo">
                <tr
                  :key="index"
                >
                  <td>
                    {{ product.category?product.category.name_uz:'' }}
                  </td>
                  <td>{{ product.product_quantity }} dona</td>
                  <td>
                    {{ product.product_price|formatPrice }}so'm
                  </td>
                  <td>
                    {{ product.product_price_with_percent|formatPrice }}so'm
                  </td>
                  <td
                    @click="goToPartyInfo(product.product.party)"
                  >
                    <a href="#">
                      {{ product.product.party.provider.name }} ({{ product.product.party.provider.director_fio }})
                    </a>
                  </td>
                  <td>
                    {{ product.product.party.date|toLocaleDateString }}
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { showToast } from '@/utils/toast'

export default {
  components: {
  },
  props: {
    boughtProductsInfo: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      reject_modal: false,
    }
  },
  computed: {
    ...mapGetters({
    }),
  },
  watch: {
  },
  methods: {
    ...mapActions({
      rejectContract: 'contract/rejectContract',
    }),
    goToPartyInfo(party) {
      this.$router.push({ name: 'warehouseParty-show', params: { id: party.id } })
    },
  },

}
</script>

<style>

</style>
