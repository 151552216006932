<template>
  <div>
    <b-modal
      v-model="icloudeModal"
      size="lg"
      centered
      hide-footer
      title="Qurilmani tizimga biriktirish"
      no-close-on-backdrop
      @close="$emit('close', true)"
      @cancel="$emit('close', true)"
    >

      <template v-if="productInfo.imei_2">
        <b-form class="auth-login-form mt-2">
          <b-form-group
            label="IMEI'ni tanlang"
            label-for="tag"
          >
            <v-select
              id="tag"
              v-model="attachForm.which"
              placeholder="IMEIni tanlang"
              :options="IMEIList"
              label="label"
              :reduce="option => option.value"
            >
              <!-- <template
              slot="option"
              slot-scope="option"
            >
              <span>
                {{ "IMEI " + option.type + "(" + option.label + ")" }}
              </span>
            </template> -->
              <span
                slot="no-options"
                @click="$refs.vSelect.open = false"
              >
                Kechirasiz, IMEI topilmadi
              </span>
            </v-select>
          </b-form-group>

          <!-- <i>Qurilma IMEI'laridan birni tanlash orqali KNOXga shu IMEI
          biriktiriladi. Har ikklasini tanlaganda ham qurilmani bloklash
          mumkin</i> -->
        </b-form>
      </template>
      <div v-else>
        <i>Rostdan ham ushbu qurilmani UzIMEI'ga biriktirmoqchimisiz?</i>
      </div>
      <div class="d-flex justify-content-end">
        <b-overlay :show="loadingBtn">
          <b-button
            variant="primary"
            class="float-right"
            size="small"
            @click="onAttach"
          >
            <feather-icon icon="SendIcon" />
            Biriktirish
          </b-button>
        </b-overlay>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { showToast } from '@/utils/toast'
import { mapActions } from 'vuex'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
  },
  props: {
    contractProductId: {
      type: Number,
      default: null,
    },
    productInfo: {
      type: Object,
      default: null,
    },
    notAttach: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      icloudeModal: true,
      iCloud_objs: null,
      iCloudsList: [],
      IMEI_objs: null,
      IMEIList: [
        { label: 'Ikkala IMEI`ni biriktirish', value: 'all' },
        { label: '1-IMEI`ni biriktirish', value: 1 },
        { label: '2-IMEI`ni biriktirish', value: 2 },
      ],
      openForm: false,
      formType: null,
      loadingBtn: false,
      loadingKnoxBtn: false,
      attachForm: {
        which: null,
        which_imei: 1,
        contract_product_id: null,
        icloud_id: null,
        imei: null,
      },
    }
  },
  computed: {
    storageUrl() {
      return `${process.env.VUE_APP_BASE_URL}storage/`
    },
    isHasNotUploaded() {
      return this.imageData.photos.some(p => p.url == '')
    },
  },
  mounted() {
    this.attachForm.contract_product_id = this.contractProductId
    if (!this.productInfo.imei_2) {
      this.attachForm.which = 1
    }
    if (!this.productInfo.imei) {
      showToast('danger', this.$t('Ushbu mahsulotda IMEI biriktirilmagan'))
      this.$emit('close', true)
    }
  },
  methods: {
    ...mapActions({
      storeKnox: 'resource/storeKnox',
      storeUzimei: 'imei/store',
    }),
    attachProduct() {
      this.attachForm.imei = this.productInfo.imei
      this.loadingBtn = true
      this.storeUzimei(this.attachForm).then(res => {
        if (res.success) {
          showToast('success', this.$t('Muvaffaqiyatli biriktirildi'))
          this.$emit('close', true)
        }
      }).finally(() => {
        this.loadingBtn = false
      })
    },

    onAttach() {
      if (this.productInfo.imei_2 && !this.attachForm.which) {
        showToast('danger', this.$t('IMEI tanlang'))
      } else {
        this.attachProduct()
      }
    },

  },
}
</script>

<style></style>
