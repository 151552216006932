<template>
  <div>
    <!-- v-if="isOperator || isAdmin || isDirector || isDeputy" -->
    <div
      class=""
    >
      <validation-observer
        ref="form"
        #default="{invalid}"
      >
        <b-form
          v-if="!loading"
          class="auth-login-form mt-2"
          @submit.prevent="submit"
        >
          <b-form-group
            :label="$t('Написать комментарий')"
            label-for="body"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Написать комментарий')"
              rules="required"
            >
              <b-form-textarea
                id="body"
                v-model="form.body"
                class="form-control"
                :state="errors.length > 0 ? false:null"
                placeholder="Комментарий"
                rows="3"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <div class="mt-2 text-right">
            <b-button
              :disabled="loading"
              type="submit"
              variant="primary"
              class="mr-1"
            >
              Опубликовать
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </div>

    <div v-if="comments && comments.length">
      <div
        v-for="(comment, index) in comments"
        :key="index"
        class="comment-card"
      >
        <div class="d-flex justify-content-between">
          <b>{{ comment.body }}</b>
          <span class="text-muted">{{ formatDateYmd(comment.created_at) }}</span>
        </div>
        <span class="text-muted">
          <feather-icon
            icon="UserIcon"
            size="14"
          />
          {{ comment.created_user?comment.created_user.name:'' }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { showToast } from '@/utils/toast'
import { required } from '@validations'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    ValidationProvider, ValidationObserver,
  },
  data() {
    return {
      form: {
        contract_id: null,
        body: null,
      },
      loading: false,
      comments: null,
      required,
    }
  },
  computed: {
    ...mapGetters({
      contract: 'contract/GET_ITEM',
    }),
    all_credit_debt() {
      // + Number(this.contract.all_penya_amount)
      return (Number(this.contract.credit_amount) - Number(this.contract.all_payment_amount))
    },
  },
  watch: {
  },
  mounted() {
    this.form.contract_id = this.$route.params.id
    this.getAction()
  },
  methods: {
    ...mapActions({
      paymentStore: 'contract/commentStore',
      getItemAction: 'contract/getComments',
    }),
    async validationForm() {
      let validated = false
      await this.$refs.form.validate().then(success => {
        validated = success
      })
      return validated
    },
    async submit() {
      const valid = await this.validationForm()
      if (valid) {
        this.loading = true
        this.paymentStore(this.form).then(res => {
          if (res.success) {
            showToast('success', this.$t('Успешно оформлен'))
            this.loading = false
            this.form.body = ''
            this.getAction()
          }
        }).catch(err => {
          if (err.data.errors && err.data.errors.body) {
            showToast('danger', err.data.errors.body[0])
          }
        })
      }
    },
    getAction() {
      this.getItemAction({ contract_id: this.$route.params.id, relations: 'createdUser' })
        .then(resp => {
          if (resp.success) {
            this.comments = resp.data.data
          }
        })
    },
  },

}
</script>

<style>

</style>
