<template>
  <div>
    <b-modal
      v-model="icloudeModal"
      size="sm"
      centered
      hide-footer
      title="Mahsulotga IMEI biriktirish"
      no-close-on-backdrop
      @close="$emit('close', true)"
      @cancel="$emit('close', true)"
    >
      <b-form class="auth-login-form mt-2">
        <b-form-group
          label="IMEI 1"
          label-for="imei_1"
        >
          <b-form-input
            id="imei_1"
            v-model="attachForm.imei_1"
            v-mask="'###############'"
            placeholder="123456789012345"
          />
        </b-form-group>
        <b-form-group
          label="IMEI 2(agar mavjud bo'lsa)"
          label-for="imei_2"
        >

          <b-form-input
            id="imei_2"
            v-model="attachForm.imei_2"
            v-mask="'###############'"
            placeholder="123456789012345"
          />
        </b-form-group>
      </b-form>
      <b-button
        variant="primary"
        class="float-right"
        size="small"
        @click="attachIMEI"
      >
        <feather-icon icon="SendIcon" />
        Biriktirish
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import { showToast } from '@/utils/toast'
import { mapActions } from 'vuex'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
  },
  props: {
    contractProductId: {
      type: Number,
      default: null,
    },
    productInfo: {
      type: Object,
      default: null,
    },
    notAttach: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      icloudeModal: true,
      iCloud_objs: null,
      iCloudsList: [],
      attachForm: {
        contract_product_id: null,
        imei_1: null,
        imei_2: null,
      },
    }
  },
  computed: {
    storageUrl() {
      return `${process.env.VUE_APP_BASE_URL}storage/`
    },
    isHasNotUploaded() {
      return this.imageData.photos.some(p => p.url == '')
    },
  },
  mounted() {
    this.attachForm.contract_product_id = this.contractProductId
    if (this.productInfo.imei_1) {
      this.attachForm.imei_1 = this.productInfo.imei_1
      this.attachForm.imei_2 = this.productInfo.imei_2
    }
  },
  methods: {
    ...mapActions({
      updateImeiProduct: 'resource/updateImeiProduct',
    }),
    attachIMEI() {
      this.attachForm.imei = this.productInfo.imei
      if (!(this.attachForm.imei_1)) {
        showToast('danger', this.$t('IMEI biriktirilmagan'))
      } else {
        this.updateImeiProduct(this.attachForm).then(res => {
          if (res.success) {
            showToast('success', this.$t('Muvaffaqiyatli biriktirildi'))
            this.$emit('close', res.data)
          }
        })
      }
    },
  },
}
</script>

<style></style>
