<template>
  <div class="card p-2">
    <b-table
      v-if="contract && contract.schedule && contract.schedule.length"
      bordered
      hover
      :items="contract.schedule"
      :fields="fields"
    >
      <template #cell(date)="data">
        {{ dateDotFormat(data.item.date) }}
      </template>
      <template #cell(amount)="data">
        {{ data.item.amount | formatPrice }} so'm
        <span
          v-if="
            contract.actual_dollar_rate && contract.actual_dollar_rate.amount
          "
          class="dblock font-weight-bold"
        >
          ({{
            (data.item.amount / contract.actual_dollar_rate.amount).toFixed(2)
          }}
          $)
        </span>
      </template>
      <template #cell(payment_amount)="data">
        <span v-if="data.item.payment_amount">
          {{ data.item.payment_amount | formatPrice }} so'm
        </span>
        <span v-else>0 so'm</span>
        <span
          v-if="
            contract.actual_dollar_rate && contract.actual_dollar_rate.amount
          "
          class="dblock font-weight-bold"
        >
          ({{
            (
              data.item.payment_amount / contract.actual_dollar_rate.amount
            ).toFixed(2)
          }}
          $)</span>
      </template>
      <template #cell(status)="data">
        <b-badge
          v-if="data.item.is_overdue"
          pill
          variant="danger"
        >
          {{ "Просрочено" }}
        </b-badge>

        <b-badge
          v-else
          pill
          :variant="data.item.status == 1 ? 'success' : 'warning'"
        >
          {{ data.item.status == 1 ? "Оплачен" : "Не оплачено" }}
        </b-badge>
      </template>
    </b-table>
    <!--  -->
    <div
      v-if="contract"
      class="mt-3"
    >
      <div class="d-flex gap-5">
        <div>
          <p>
            <b>Оплачено:</b>
            <span class="text-success">
              {{ contract.all_payment_amount | formatPrice }} so'm</span>
          </p>
          <p>
            <b>Не оплачено:</b>
            <span class="text-warning">
              {{ all_credit_debt | formatPrice }} so'm</span>
          </p>
          <p>
            <b>Просрочено:</b>
            <span class="text-danger">
              {{ contract.overdue_amount | formatPrice }} so'm</span>
            <!-- Sudga oshirish -->
          </p>
        </div>

        <div
          v-if="contract.is_dollar"
          class="ml-5"
        >
          <p>
            <b>Оплачено:</b>
            <span class="text-success">
              {{ contract.all_payment_amount_dollar | formatPrice }} $</span>
          </p>
          <p>
            <b>Не оплачено:</b>
            <span class="text-warning">
              {{ all_credit_debt_dollar | formatPrice }} $</span>
          </p>
        </div>
        <div
          v-if="hasPermission('uni_access.sendSelectedContractsUniAccess')"
          class="ml-3"
        >
          <b-button
            variant="primary"
            class="mb-2 "
            @click="openModalAutopay=true"
          >
            Autopayga yuborish
          </b-button>
        </div>
      </div>

      <!-- Sudga oshirish -->
      <send-court
        v-can="'give-court-contract'"
        :contract="contract"
        :contract-type="contractType"
        @refresh="$emit('refresh', true)"
      />

      <!-- autopayga yuborish -->
      <div v-if="openModalAutopay">
        <send-autopay
          v-can="'uni_access.sendSelectedContractsUniAccess'"
          :contract="contract"
          :contract-type="contractType"
          @close="openModalAutopay=false"
          @refresh="$emit('refresh', true)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import sendCourt from '../sendCourt.vue'
import sendAutopay from '../sendAutopay.vue'

export default {
  components: { sendCourt, sendAutopay },
  props: {
    contract: {
      type: Object,
      default: () => {},
    },
    contractType: {
      type: String,
      default: () => 'new',
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'date',
          label: 'Дата',
        },
        {
          key: 'amount',
          label: 'Сумма',
        },
        {
          key: 'payment_amount',
          label: 'Оплачено',
        },
        {
          key: 'status',
          label: 'Статус',
        },
      ],
      openModalAutopay: false,
    }
  },
  computed: {
    all_credit_debt() {
      if (this.contract) {
        return (
          Number(this.contract.credit_amount)
          - Number(this.contract.all_payment_amount)
        )
      }
      return 0
    },
    all_credit_debt_dollar() {
      if (this.contract) {
        return (
          Number(this.contract.credit_amount_dollar)
          - Number(this.contract.all_payment_amount_dollar)
        )
      }
      return 0
    },
  },
}
</script>

<style></style>
