<template>
  <div>
    <b-modal
      v-model="icloudeModal"
      size="sm"
      centered
      hide-footer
      title="Qarzdorlikni avtoto'lovga yuborish"
      no-close-on-backdrop
      @close="$emit('close', true)"
      @cancel="$emit('close', true)"
    >
      <b-form class="auth-login-form mt-2">
        <b-form-group
          label="Miqdorini kiriting"
          label-for="amount"
        >
          <cleave
            id="amount"
            v-model="attachForm.amount"
            class="form-control"
            :options="cleaveOption"
            placeholder="0"
          />
        </b-form-group>
      </b-form>
      <p>
        <b>To'lanmagan summasi:</b>
        <span class="text-warning">
          {{ all_credit_debt | formatPrice }} so'm
        </span>
      </p>
      <p>
        <b>Qarzdorlik summasi:</b>
        <span class="text-danger">
          {{ contract.overdue_amount | formatPrice }} so'm</span>
      </p>
      <b-row class="float-right">
        <b-overlay :show="loadingBtn">
          <b-button
            variant="primary"
            class="float-right"
            size="small"
            @click="sendAutopay(1)"
          >
            <feather-icon icon="SendIcon" />
            Yuborish
          </b-button>
        </b-overlay>
      </b-row>
      <b-row
        v-if="contract.client2 || contract.pin2"
        class="float-left"
      >
        <b-overlay :show="loadingBtn">
          <b-button
            variant="outline-info"
            class="float-right"
            size="small"
            @click="sendAutopay(2)"
          >
            <feather-icon icon="SendIcon" />
            Kafildan undirish
          </b-button>
        </b-overlay>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { showToast } from '@/utils/toast'
import { mapActions } from 'vuex'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    Cleave,
  },
  props: {
    contract: {
      type: Object,
      default: null,
    },
    contractType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      icloudeModal: true,
      iCloud_objs: null,
      loadingBtn: false,
      iCloudsList: [],
      attachForm: {
        contract_id: null,
        old_contract_id: null,
        amount: null,
        is_client2: false,
      },
      cleaveOption: { numeral: true, numeralThousandsGroupStyle: 'thousand' },
    }
  },
  computed: {
    all_credit_debt() {
      if (this.contract.is_dollar) {
        return (
          (Number(this.contract.credit_amount_dollar)
            - Number(this.contract.all_payment_amount_dollar))
          * this.dollarRate
        )
      }
      return (
        Number(this.contract.credit_amount)
        - Number(this.contract.all_payment_amount)
        + Number(this.contract.penalty_amount)
      )
    },
  },
  watch: {
    'attachForm.amount': function (val) {
      if (Number(val) > Number(this.all_credit_debt) + 100) {
        this.attachForm.amount = this.all_credit_debt
      }
    },
  },
  mounted() {
    if (this.contractType == 'old') {
      this.attachForm.old_contract_id = this.$route.params.id
    } else {
      this.attachForm.contract_id = this.$route.params.id
    }
  },
  methods: {
    ...mapActions({
      sendManualAmountUniaccess: 'resource/sendManualAmountUniaccess',
      sendManualAmountOldUniaccess: 'resource/sendManualAmountOldUniaccess',
    }),
    methodAction(params) {
      if (this.contractType == 'old') {
        return this.sendManualAmountOldUniaccess(params)
      }
      return this.sendManualAmountUniaccess(params)
    },
    sendAutopay(clientType = 1) {
      if (!this.attachForm.amount) {
        showToast('danger', this.$t('Summa kiritilmagan'))
      } else {
        if (clientType == 2) {
          this.attachForm.is_client2 = true
        }
        this.loadingBtn = true
        this.methodAction(this.attachForm)
          .then(res => {
            if (res.success) {
              showToast('success', res.data)
              this.$emit('close', res.data)
            } else {
              showToast('danger', res.message, 'XIcon')
            }
          })
          .finally(() => {
            this.loadingBtn = false
          })
      }
    },
  },
}
</script>

<style></style>
