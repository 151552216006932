<template>
  <div class="topHr">
    <template v-if="contract && contract.court_status == 1">
      <span
        v-if="contract && contract.court_status == 1"
        class="text-warning font-weight-bold fs19"
      >Sudga oshirilgan</span>
      <a
        v-if="contract.court_letter_url"
        class="text-center mt-2"
        :href="storageUrl + contract.court_letter_url"
        target="_blank"
      >
        <div>
          <img
            height="162"
            width="162"
            class="img-uploaded"
            :src="storageUrl + contract.court_letter_url"
            alt="court letter"
          >
          <span
            class="upload-label"
            style="margin: 0 auto 1rem"
          > Xat </span>
        </div>
      </a>
      <hr>
      <b-button
        v-can="'upload-court-decision'"
        variant="warning"
        class="mt-2"
        @click="uploadDecision"
      >
        Qarorni yuklash
      </b-button>
    </template>
    <template v-else-if="contract && contract.court_status == 2">
      <span
        class="text-warning font-weight-bold fs16 mr-3"
      >Sudga oshirilgan</span>
      <span
        class="text-success font-weight-bold fs19"
      >Qaror kuchga kirgan</span>
      <p><b>Jarima miqdori:</b> {{ contract.penalty_amount }} so'm</p>
      <p><b>Sud qaroriga izoh: </b> {{ contract.court_decision_comment }}</p>
      <div class="d-flex justify-content-around mt-2">
        <a
          v-if="contract.court_letter_url"
          class="text-center"
          :href="storageUrl + contract.court_letter_url"
          target="_blank"
        >
          <div>
            <img
              height="162"
              width="162"
              class="img-uploaded"
              :src="storageUrl + contract.court_letter_url"
              alt="court letter"
            >
            <span
              class="upload-label"
              style="margin: 0 auto 1rem"
            > Xat </span>
          </div>
        </a>
        <a
          v-if="contract.court_decision_url"
          class="text-center"
          :href="storageUrl + contract.court_decision_url"
          target="_blank"
        >
          <div>
            <img
              height="162"
              width="162"
              class="img-uploaded"
              :src="storageUrl + contract.court_decision_url"
              alt="court letter"
            >
            <span
              class="upload-label"
              style="margin: 0 auto 1rem"
            >
              Qaror
            </span>
          </div>
        </a>
      </div>
    </template>
    <!-- Sudga oshirish -->
    <b-button
      v-else-if="contract.is_rejected != 1"
      v-can="'give-court-contract'"
      variant="warning"
      class=""
      @click="court_modal = true"
    >
      Sudga oshirish
    </b-button>
    <!-- akt -->
    <b-button
      v-if="contract && !$route.path.includes('contract-old')"
      variant="info"
      class="ml-2"
      @click="getAktSverka"
    >
      Акт сверка
    </b-button>
    <!-- Sudga oshirish -->
    <b-button
      v-if="contract"
      v-can="'give-court-contract'"
      variant="outline-success"
      class="ml-2"
      @click="uploadAdditionalFile"
    >
      <feather-icon
        icon="PaperclipIcon"
        size="12"
      />
      Qo'shimcha fayl yuklash
    </b-button>
    <!-- modal -->
    <div v-if="court_modal">
      <b-modal
        v-model="court_modal"
        size="md"
        centered
        :title="modalTitle"
        hide-footer
        no-close-on-backdrop
        @close="court_modal = false"
        @cancel="court_modal = false"
      >
        <div
          v-loading="loads"
          class="text-center"
        >
          <!-- add_file -->
          <template
            v-if="
              additional_document_url &&
                additional_document_url.slice(-3) == 'pdf'
            "
          >
            <div @click="openPdf(storageUrl + additional_document_url)">
              <pdf
                :src="storageUrl + additional_document_url"
                height="162"
                width="162"
                class="img-uploaded"
              />
            </div>
          </template>
          <template v-else-if="additional_document_url">
            <img
              :src="storageUrl + additional_document_url"
              height="162"
              width="162"
              class="img-uploaded"
            >
          </template>
          <template v-else-if="uploadType != 'decision'">
            <template
              v-if="
                form.court_letter_url &&
                  form.court_letter_url.slice(-3) == 'pdf'
              "
            >
              <div @click="openPdf(storageUrl + form.court_letter_url)">
                <pdf
                  :src="storageUrl + form.court_letter_url"
                  height="162"
                  width="162"
                  class="img-uploaded"
                />
              </div>
            </template>
            <template v-else-if="form.court_letter_url">
              <img
                :src="storageUrl + form.court_letter_url"
                height="162"
                width="162"
                class="img-uploaded"
              >
            </template>
            <template v-else>
              <img
                src="@/assets/images/svg/fileuploader-placeholder.svg"
                class="img-placeholder"
                alt="image"
                width="162"
                @click="$refs.firstPassport.click()"
              >
            </template>
          </template>
          <template v-else>
            <!-- decision -->
            <template
              v-if="
                form.court_decision_url &&
                  form.court_decision_url.slice(-3) == 'pdf'
              "
            >
              <div @click="openPdf(storageUrl + form.court_decision_url)">
                <pdf
                  :src="storageUrl + form.court_decision_url"
                  height="162"
                  width="162"
                  class="img-uploaded"
                />
              </div>
            </template>
            <template v-else-if="form.court_decision_url">
              <img
                :src="storageUrl + form.court_decision_url"
                height="162"
                width="162"
                class="img-uploaded"
              >
            </template>
            <template v-else>
              <img
                src="@/assets/images/svg/fileuploader-placeholder.svg"
                class="img-placeholder"
                alt="image"
                width="162"
                @click="$refs.firstPassport.click()"
              >
            </template>
          </template>
        </div>
        <div class="text-center">
          <input
            v-if="uploadType == 'decision'"
            ref="firstPassport"
            accept="image/jpeg, image/jpg, image/png, application/pdf"
            type="file"
            class="d-none"
            @change="(e) => onChangeFile(e, 1, 'court_decision')"
          >
          <input
            v-else-if="uploadType == 'add_file'"
            ref="firstPassport"
            accept="image/jpeg, image/jpg, image/png, application/pdf"
            type="file"
            class="d-none"
            @change="(e) => onChangeFile(e, 1, 'contract-documents')"
          >
          <input
            v-else
            ref="firstPassport"
            accept="image/jpeg, image/jpg, image/png, application/pdf"
            type="file"
            class="d-none"
            @change="(e) => onChangeFile(e, 1, 'court_letter')"
          >
          <span
            class="upload-label"
            @click="$refs.firstPassport.click()"
          >
            <feather-icon
              icon="PlusSquareIcon"
              size="12"
            /> {{ uploadName }}
          </span>
        </div>
        <template v-if="uploadType == 'add_file'">
          <div>
            <label for="court_decision_comment">Fayl nomi</label>
            <b-form-input
              id="court_decision_comment"
              v-model="additional_file_name"
              placeholder="Faylni nomlang"
            />
          </div>
        </template>
        <!-- Comment -->
        <template v-else-if="contract && contract.court_status == 1">
          <div>
            <label for="penalty_amount">Jarima miqdori (so'm)</label>
            <cleave
              id="penalty_amount"
              v-model="form.penalty_amount"
              class="form-control"
              :options="cleaveOption"
              placeholder="0"
            />
          </div>
          <div>
            <label for="court_decision_comment">Sud qaroriga izoh</label>
            <b-form-input
              id="court_decision_comment"
              v-model="form.court_decision_comment"
              placeholder="Izoh"
            />
          </div>
        </template>
        <div class="mt-2 text-right">
          <b-button
            :disabled="loading && !form.court_letter_url"
            variant="primary"
            class="mr-1"
            @click="send"
          >
            Yuborish
          </b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { showToast } from '@/utils/toast'
import { mapActions } from 'vuex'
import pdf from 'vue-pdf'
import Cleave from 'vue-cleave-component'

export default {
  components: { pdf, Cleave },
  props: {
    contract: {
      type: Object,
      default: () => {},
    },
    contractType: {
      type: String,
      default: () => 'new',
    },
  },
  data() {
    return {
      court_modal: false,
      loads: false,
      loading: false,
      uploadName: 'Xatni yuklash',
      modalTitle: 'Sudga oshirish',
      uploadType: 'letter',
      cleaveOption: { numeral: true, numeralThousandsGroupStyle: 'thousand' },
      additional_document_url: '',
      additional_file_name: '',
      form: {
        court_letter_url: '',
        court_decision_url: null,
        penalty_amount: null,
        court_decision_comment: null,
        contract_id: null,
      },
    }
  },
  computed: {
    storageUrl() {
      return `${process.env.VUE_APP_BASE_URL}storage/`
    },
  },
  methods: {
    ...mapActions({
      fileUpload: 'client/fileUpload',
      sendCourt: 'contract/sendCourt',
      makeAktSverka: 'contract/makeAktSverka',
      uploadCourtDecision: 'contract/uploadCourtDecision',
      uploadAddDocument: 'contract/uploadAddDocument',
    }),
    send() {
      this.loading = true
      const data = {}
      if (this.contractType == 'old') {
        data.old_contract_id = this.contract.id
      } else {
        data.contract_id = this.contract.id
      }
      if (this.uploadType == 'add_file') {
        this.uploadAddDocument({ id: this.contract.id, name: this.additional_file_name, url: this.additional_document_url })
          .then(res => {
            if (res.success) {
              showToast('success', this.$t('Muvaffaqiyatli saqlandi'))
              this.$emit('refresh', true)
              this.court_modal = false
            }
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        if (this.contract && this.contract.court_status == 1) {
          if (!this.form.penalty_amount) {
            showToast('danger', 'Jarima miqdorini kiriting', 'XIcon')
            this.loading = false
            return false
          }
          if (!this.form.court_decision_comment) {
            showToast('danger', 'Izohni kiriting', 'XIcon')
            this.loading = false
            return false
          }
        }
        if (this.uploadType == 'decision') {
          data.court_decision_url = this.form.court_decision_url
          data.penalty_amount = this.form.penalty_amount
          data.court_decision_comment = this.form.court_decision_comment
          this.uploadCourtDecision(data)
            .then(res => {
              if (res.success) {
                showToast('success', this.$t('Muvaffaqiyatli yuborildi'))
                this.$router.push({ name: 'BroughtToCourt' })
              }
            })
            .finally(() => {
              this.loading = false
            })
        } else {
          data.court_letter_url = this.form.court_letter_url
          this.sendCourt(data)
            .then(res => {
              if (res.success) {
                showToast('success', this.$t('Muvaffaqiyatli yuborildi'))
                this.$router.push({ name: 'BroughtToCourt' })
              }
            })
            .finally(() => {
              this.loading = false
            })
        }
      }
      return true
    },
    getAktSverka() {
      this.makeAktSverka({ id: this.contract.id }).then(res => {
        window.open(`${this.storageUrl}akt-sverka/${res.data}.pdf`, '_blank')
      })
    },
    uploadDecision() {
      this.court_modal = true
      this.uploadType = 'decision'
      this.modalTitle = 'Sud qarorini yuklang'
      this.uploadName = 'Qarorni yuklash'
    },
    uploadAdditionalFile() {
      this.court_modal = true
      this.uploadType = 'add_file'
      this.modalTitle = 'Qo`shimcha fayl  yuklang'
      this.uploadName = 'Faylni yuklash'
    },
    async onChangeFile(event, type, directory) {
      if (event.target.files[0].size <= 15360000) {
        this.loads = true
        const fileData = new FormData()
        fileData.append('file', event.target.files[0])
        fileData.append('directory', directory)
        this.fileUpload(fileData)
          .then(res => {
            if (this.uploadType == 'decision') {
              this.form.court_decision_url = res.data.path
            } else if (this.uploadType == 'add_file') {
              this.additional_document_url = res.data.path
            } else {
              this.form.court_letter_url = res.data.path
            }
          })
          .catch(err => {
            console.log(err, err.message)
            if (err.data && err.data.message) {
              showToast('danger', err.data.message, 'XIcon')
            } else {
              showToast('danger', err, 'XIcon')
            }
          })
          .finally(() => {
            this.loads = false
          })
      } else {
        showToast(
          'danger',
          this.$t('Файл ҳажми 15 МБ дан кичик бўлиши лозим'),
          'XIcon',
        )
      }
    },
  },
}
</script>

<style></style>
